export const FamilyImage =
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/1AE878F7-CC80-4C93-9446-D9012AFE489A_1_105_c.jpeg";
export const EventImage =
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/05F8FC91-B921-407C-9890-F239193F6A36_1_105_c.jpeg";
export const AllImages = [
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/1153C2EE-2B82-410A-8715-F96240070253_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/0442D82F-AAA6-4009-989B-9C41836CCDFB.jpg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/89B66795-C390-4EB4-8D38-EADD54FA69CD_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/2B6E8A57-4925-413A-B6D7-4EF67C7717AE.jpg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/CA09C450-0091-4AFB-A808-A3AB8C08516F_1_201_a.heic",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7424.JPG",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/56E87969-9EAF-42D4-9BAE-4B8162988B5A_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/img_6606_fotor.jpg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/2EFAE07A-B939-41BC-94F6-7FD05C74DC93_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/img_6778_fotor.jpg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/6CCE8741-F5D5-4D05-BD60-6494644749AD_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/4E7B217D-A894-4137-9877-3D8A4F1F5808_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7379.JPG",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/85D5BE1E-D84B-406F-96AF-8DF0714C0BBE_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/5FA22019-290F-4A2D-9C73-87BCE17D360B_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/C69F4857-8A5A-435B-A948-268EF8E44F3A.jpg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/809C694B-1740-47BC-B4E0-A18397ED096F_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7409.JPG",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/02E24547-D914-4E0C-9E93-4B64F4F9A47C.jpg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/594E7BF2-CD92-43BA-B701-C1B471D64EA1_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/img_6956_fotor.jpg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7332.JPG",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7454.JPG",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/5DE0A08D-17B6-49B6-8305-F2272DE07F1C.jpg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7480.JPG",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/img_6705_fotor.jpg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/05F8FC91-B921-407C-9890-F239193F6A36_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/773C1B50-571F-4587-A727-1E309B6794DD.jpg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/C625C9DA-5EF9-4D77-A1A2-38CAB4B70AB2_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/93F12872-C5ED-4AEC-910F-12139F08E454_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7489.JPG",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7462.JPG",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/7E02FC7A-C8EF-47CF-B364-10BE86009AF6_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7339.JPG",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/DB248AA2-7344-43DA-B180-953991D3662D_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/8645D5F6-A63B-4E43-8096-DE73CF816AA6_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/4CA312A4-8D36-4F23-9C96-3493B014A759_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7377.JPG",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/F2903838-6123-4883-8AC6-A3D30D557921_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/95B70164-B085-42B0-9466-63329C2D0B66_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/51F25FE1-E775-467D-9574-BFDABE8726EA_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/1AE878F7-CC80-4C93-9446-D9012AFE489A_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/AFF1E753-BD1A-4B83-B75B-3E76A7B944B0_1_201_a.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/img_6537_fotor.jpg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/F9CE9041-1445-4DEE-90D1-B1FCDB312EF4.jpg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7358.JPG",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/BA2F2FD5-FD93-4BBA-8B89-707E8FFCB8B9_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/BADBB9F7-5C4F-4531-8503-5FE9C9780461.jpg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/img_7009_fotor.jpg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/img_6748_fotor.jpg",
];

export const EventImages = [
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/img_6537_fotor.jpg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/img_6748_fotor.jpg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/img_6956_fotor.jpg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7358.JPG",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7424.JPG",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7409.JPG",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7409.JPG",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7454.JPG",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7480.JPG",
];

const FamilyImages = [
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/2EFAE07A-B939-41BC-94F6-7FD05C74DC93_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/4CA312A4-8D36-4F23-9C96-3493B014A759_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/4E7B217D-A894-4137-9877-3D8A4F1F5808_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/05F8FC91-B921-407C-9890-F239193F6A36_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/5FA22019-290F-4A2D-9C73-87BCE17D360B_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/6CCE8741-F5D5-4D05-BD60-6494644749AD_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/7E02FC7A-C8EF-47CF-B364-10BE86009AF6_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/85D5BE1E-D84B-406F-96AF-8DF0714C0BBE_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/89B66795-C390-4EB4-8D38-EADD54FA69CD_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/93F12872-C5ED-4AEC-910F-12139F08E454_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/95B70164-B085-42B0-9466-63329C2D0B66_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/594E7BF2-CD92-43BA-B701-C1B471D64EA1_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/809C694B-1740-47BC-B4E0-A18397ED096F_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/1153C2EE-2B82-410A-8715-F96240070253_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/AFF1E753-BD1A-4B83-B75B-3E76A7B944B0_1_201_a.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/CA09C450-0091-4AFB-A808-A3AB8C08516F_1_201_a.heic",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/DB248AA2-7344-43DA-B180-953991D3662D_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/F2903838-6123-4883-8AC6-A3D30D557921_1_105_c.jpeg",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7332.JPG",
  "https://f005.backblazeb2.com/file/chicago-neighborhood-soccer/IMG_7339.JPG",
];

export const images = FamilyImages.map((i) => {
  return {
    original: i,
    thumbnail: i,
  };
});
