const Error = () => {
  return (
    <div>
      We made a mistake. Sorry about that. Please send an email to
      alberto@safa.ai.
    </div>
  );
};

export default Error;
